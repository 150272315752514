var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
            scrollable: "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "history-button",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.state.historyShow = true
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "clock-rotate-left"] },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("button.history")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  !_vm.isAccessDenied
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "entity_selector.error.insufficient_permission_to_add_entity",
                          [_vm.$t("fields.title").toLowerCase()]
                        )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "triangle-exclamation"] },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isNameVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("fields.field.name"),
                                      "label-for": "name",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }",
                                            },
                                          ],
                                          attrs: {
                                            id: "name",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("fields.field.name"),
                                            "data-vv-name": "fields.field.name",
                                            maxlength: _vm.maxNameLength,
                                            "data-vv-delay": "500",
                                            readonly: _vm.isNameReadOnly,
                                            autofocus: true,
                                            state:
                                              _vm.fieldValidateUtil.stateValidate(
                                                _vm.isReadOnly,
                                                _vm.veeFields,
                                                _vm.errors,
                                                "fields.field.name"
                                              ),
                                            trim: "",
                                          },
                                          nativeOn: {
                                            keydown: function ($event) {
                                              return _vm.nameKeydownHandler.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.fields.name,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.fields, "name", $$v)
                                            },
                                            expression: "fields.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: { "d-block": _vm.showNameError },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "fields.field.name"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isTypeVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "field-type",
                                      label: _vm.$t("fields.field.type"),
                                      "label-for": "type",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-dropdown",
                                      {
                                        staticClass:
                                          "custom-dropdown value-dropdown",
                                        attrs: {
                                          lazy: "",
                                          text: _vm.getFieldTypeLabel(),
                                          disabled: _vm.isTypeReadOnly,
                                        },
                                      },
                                      [
                                        _vm._l(
                                          _vm.typeOptions,
                                          function (item, index) {
                                            return [
                                              _c(
                                                "b-dropdown-item",
                                                {
                                                  key: index,
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onFieldTypeOptionClick(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.text) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: { "d-block": _vm.showTypeError },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("fields.type")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isDisplayNameVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "fields.field.display_name"
                                      ),
                                      "label-for": "display_name",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }",
                                            },
                                          ],
                                          attrs: {
                                            id: "display_name",
                                            type: "text",
                                            "data-vv-as": _vm.$t(
                                              "fields.field.display_name"
                                            ),
                                            "data-vv-name":
                                              "fields.display_name",
                                            maxlength: _vm.maxNameLength,
                                            "data-vv-delay": "500",
                                            readonly: _vm.isNameReadOnly,
                                            state:
                                              _vm.fieldValidateUtil.stateValidate(
                                                _vm.isReadOnly,
                                                _vm.veeFields,
                                                _vm.errors,
                                                "fields.display_name"
                                              ),
                                            trim: "",
                                          },
                                          nativeOn: {
                                            keydown: function ($event) {
                                              return _vm.keydownHandler.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.fields.displayName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.fields,
                                                "displayName",
                                                $$v
                                              )
                                            },
                                            expression: "fields.displayName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showDisplayNameError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "fields.display_name"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          { staticClass: "pr-0", attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "field-type",
                                  label: _vm.$t("fields.field.append_after"),
                                  "label-for": "type",
                                },
                              },
                              [
                                _c(
                                  "b-dropdown",
                                  {
                                    staticClass:
                                      "custom-dropdown value-dropdown",
                                    attrs: {
                                      lazy: "",
                                      text: _vm.getFieldAppendAfterLabel(),
                                      disabled: _vm.isReadOnly,
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.appendAfterOpts,
                                      function (item, index) {
                                        return [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              key: index,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onFieldAppendAfterOptionClick(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " + _vm._s(item.text) + " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    staticClass:
                                      "alert-danger form-field-alert",
                                    class: { "d-block": _vm.showTypeError },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "circle-exclamation"],
                                      },
                                    }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(
                                          _vm.errors.first("fields.type")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "pr-0", attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t("fields.field.def"),
                                  "label-for": "default_value",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _vm.fields.type !== "Date" &&
                                    _vm.fields.type !== "Boolean"
                                      ? _c("b-form-input", {
                                          attrs: {
                                            id: "default_value",
                                            type: _vm.defaultValueType,
                                            min: _vm.defaultValueMin,
                                            max: _vm.defaultValueMax,
                                            trim: "",
                                          },
                                          on: { blur: _vm.defaultBlur },
                                          nativeOn: {
                                            keydown: function ($event) {
                                              return _vm.keydownHandler.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.fields.def,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.fields, "def", $$v)
                                            },
                                            expression: "fields.def",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.fields.type === "Boolean"
                                      ? _c("b-form-radio-group", {
                                          staticClass: "ml-1 radio-group-class",
                                          attrs: {
                                            size: "sm",
                                            options: _vm.boolOptions,
                                          },
                                          model: {
                                            value: _vm.fields.def,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.fields, "def", $$v)
                                            },
                                            expression: "fields.def",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.fields.type === "Date"
                                      ? _c("b-form-datepicker", {
                                          staticClass: "date-height",
                                          attrs: {
                                            "today-button": "",
                                            "reset-button": "",
                                            "close-button": "",
                                            "hide-header": "",
                                            "label-today-button":
                                              _vm.$t("date.today"),
                                            "label-reset-button":
                                              _vm.$t("date.reset"),
                                            "label-close-button":
                                              _vm.$t("date.close"),
                                            "today-button-variant": "primary",
                                            "reset-button-variant": "danger",
                                            "close-button-variant": "secondary",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function ({}) {
                                                  return [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "calendar-days",
                                                        ],
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2621928167
                                          ),
                                          model: {
                                            value: _vm.fields.def,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.fields, "def", $$v)
                                            },
                                            expression: "fields.def",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    staticClass:
                                      "alert-danger form-field-alert",
                                    class: { "d-block": _vm.showDefError },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "circle-exclamation"],
                                      },
                                    }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(_vm.errors.first("fields.def")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.showMinMax
                          ? [
                              _c(
                                "b-col",
                                { staticClass: "pr-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.$t("fields.field.min"),
                                        "label-for": "min_value",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _vm.fields.type !== "Date"
                                            ? _c("b-form-input", {
                                                attrs: {
                                                  id: "min_value",
                                                  type: _vm.defaultValueType,
                                                  min: _vm.defaultValueMin,
                                                  max: _vm.defaultValueMax,
                                                  trim: "",
                                                },
                                                on: { blur: _vm.minBlur },
                                                nativeOn: {
                                                  keydown: function ($event) {
                                                    return _vm.keydownHandler.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.fields.min,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.fields,
                                                      "min",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fields.min",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.fields.type === "Date"
                                            ? _c("b-form-datepicker", {
                                                staticClass: "date-height",
                                                attrs: {
                                                  "today-button": "",
                                                  "reset-button": "",
                                                  "close-button": "",
                                                  "hide-header": "",
                                                  "label-today-button":
                                                    _vm.$t("date.today"),
                                                  "label-reset-button":
                                                    _vm.$t("date.reset"),
                                                  "label-close-button":
                                                    _vm.$t("date.close"),
                                                  "today-button-variant":
                                                    "primary",
                                                  "reset-button-variant":
                                                    "danger",
                                                  "close-button-variant":
                                                    "secondary",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "button-content",
                                                      fn: function ({}) {
                                                        return [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "calendar-days",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2621928167
                                                ),
                                                model: {
                                                  value: _vm.fields.min,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.fields,
                                                      "min",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fields.min",
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "alert-danger form-field-alert",
                                              class: {
                                                "d-block": _vm.showMinError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "fields.min"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "pr-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.$t("fields.field.max"),
                                        "label-for": "max_value",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _vm.fields.type !== "Date"
                                            ? _c("b-form-input", {
                                                attrs: {
                                                  id: "max_value",
                                                  type: _vm.defaultValueType,
                                                  min: _vm.defaultValueMin,
                                                  max: _vm.defaultValueMax,
                                                  trim: "",
                                                },
                                                on: { blur: _vm.maxBlur },
                                                nativeOn: {
                                                  keydown: function ($event) {
                                                    return _vm.keydownHandler.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.fields.max,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.fields,
                                                      "max",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fields.max",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.fields.type === "Date"
                                            ? _c("b-form-datepicker", {
                                                staticClass: "date-height",
                                                attrs: {
                                                  "today-button": "",
                                                  "reset-button": "",
                                                  "close-button": "",
                                                  "hide-header": "",
                                                  "label-today-button":
                                                    _vm.$t("date.today"),
                                                  "label-reset-button":
                                                    _vm.$t("date.reset"),
                                                  "label-close-button":
                                                    _vm.$t("date.close"),
                                                  "today-button-variant":
                                                    "primary",
                                                  "reset-button-variant":
                                                    "danger",
                                                  "close-button-variant":
                                                    "secondary",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "button-content",
                                                      fn: function ({}) {
                                                        return [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "calendar-days",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2621928167
                                                ),
                                                model: {
                                                  value: _vm.fields.max,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.fields,
                                                      "max",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fields.max",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.fields.type !== "Boolean"
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "fields.field.req_regex_fmt"
                                      ),
                                      "label-for": "req_regex_fmt",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "req_regex_fmt",
                                            type: "text",
                                            trim: "",
                                          },
                                          nativeOn: {
                                            keydown: function ($event) {
                                              return _vm.keydownHandler.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.fields.regex,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.fields, "regex", $$v)
                                            },
                                            expression: "fields.regex",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          { staticClass: "pr-0", attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: { id: "not_null" },
                                        model: {
                                          value: _vm.fields.notNull,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.fields, "notNull", $$v)
                                          },
                                          expression: "fields.notNull",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("fields.field.notNull")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12 pr-0" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t("fields.field.description"),
                                  "label-for": "description",
                                },
                              },
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    id: "description",
                                    "max-rows": 6,
                                    trim: "",
                                    rows: 3,
                                  },
                                  model: {
                                    value: _vm.fields.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fields, "description", $$v)
                                    },
                                    expression: "fields.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _vm.entitiesData.length > 1
                      ? _c("label", [_vm._v(_vm._s(_vm.$t("fields.usage")))])
                      : _vm._e(),
                    _vm.entitiesData.length > 1
                      ? _c(
                          "b-row",
                          _vm._l(_vm.entitiesData, function (entity, index) {
                            return _c(
                              "b-col",
                              { key: index, attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            model: {
                                              value:
                                                _vm.entitiesData[index].checked,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.entitiesData[index],
                                                  "checked",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "entitiesData[index].checked",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(entity.name) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("FieldHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: `${_vm.entity}.${_vm.id}`,
                    entityType: "FIELD",
                    fieldType: _vm.fields.type,
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }