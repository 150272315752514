<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <b-button class="history-button" variant="secondary" size="sm" @click="state.historyShow = true">
            <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
            {{ $t('button.history') }}
          </b-button>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>
      
      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('fields.title').toLowerCase()]) }}</span>
        </div>
      </template>
      <template v-else>
      
        <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" class="pr-0">
              <b-form-group :label="$t('fields.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('fields.field.name')"
                    data-vv-name="fields.field.name"
                    :maxlength="maxNameLength"
                    data-vv-delay="500"
                    v-model="fields.name" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    :autofocus="true"
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'fields.field.name')"
                    trim 
                    @keydown.native="nameKeydownHandler">
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('fields.field.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col v-if="isTypeVisible" cols="12" class="pr-0">
              <b-form-group id="field-type" :label="$t('fields.field.type')" label-for="type">
                <b-dropdown lazy class="custom-dropdown value-dropdown" :text="getFieldTypeLabel()" :disabled="isTypeReadOnly">
                  <template v-for="(item, index) in typeOptions">
                    <b-dropdown-item v-bind:key="index" @click="onFieldTypeOptionClick(item)">
                      <span>
                        {{ item.text }}
                      </span>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showTypeError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('fields.type') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>            

            <b-col v-if="isDisplayNameVisible" cols="12" class="pr-0">
              <b-form-group :label="$t('fields.field.display_name')" label-for="display_name">
                <b-input-group>
                  <b-form-input id="display_name" type="text"
                    :data-vv-as="$t('fields.field.display_name')"
                    data-vv-name="fields.display_name"
                    :maxlength="maxNameLength"
                    data-vv-delay="500"
                    v-model="fields.displayName" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'fields.display_name')"
                    trim 
                    @keydown.native="keydownHandler">
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDisplayNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('fields.display_name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="pr-0">
              <b-form-group id="field-type" :label="$t('fields.field.append_after')" label-for="type">
                <b-dropdown lazy class="custom-dropdown value-dropdown" :text="getFieldAppendAfterLabel()" :disabled="isReadOnly">
                  <template v-for="(item, index) in appendAfterOpts">
                    <b-dropdown-item v-bind:key="index" @click="onFieldAppendAfterOptionClick(item)">
                      <span>
                        {{ item.text }}
                      </span>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showTypeError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('fields.type') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
           
            <b-col cols="12" class="pr-0">
              <b-form-group :label="$t('fields.field.def')" label-for="default_value">
                <b-input-group>
                  <b-form-input v-if="fields.type !== 'Date' && fields.type !== 'Boolean'" id="default_value" 
                    :type="defaultValueType"
                    :min="defaultValueMin"
                    :max="defaultValueMax"
                    v-model="fields.def" 
                    trim 
                    @blur="defaultBlur"
                    @keydown.native="keydownHandler">
                  </b-form-input>
                  <b-form-radio-group
                    v-if="fields.type === 'Boolean'"
                    class="ml-1 radio-group-class"
                    size="sm"
                    v-model="fields.def"
                    :options="boolOptions">
                  </b-form-radio-group>
                  <b-form-datepicker
                    v-if="fields.type === 'Date'"
                    v-model="fields.def"
                    class="date-height"
                    today-button
                    reset-button
                    close-button
                    hide-header
                    :label-today-button="$t('date.today')"
                    :label-reset-button="$t('date.reset')"
                    :label-close-button="$t('date.close')"
                    today-button-variant="primary"
                    reset-button-variant="danger" 
                    close-button-variant="secondary"
                  >
                    <template v-slot:button-content="{ }">
                      <font-awesome-icon :icon="['far', 'calendar-days']" />
                    </template>
                  </b-form-datepicker>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDefError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('fields.def') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          
            <template v-if="showMinMax">
              <b-col cols="12" class="pr-0">
                <b-form-group :label="$t('fields.field.min')" label-for="min_value">
                  <b-input-group>
                    <b-form-input v-if="fields.type !== 'Date'" id="min_value"
                      :type="defaultValueType"
                      :min="defaultValueMin"
                      :max="defaultValueMax"
                      v-model="fields.min" 
                      trim 
                      @blur="minBlur"
                      @keydown.native="keydownHandler">
                    </b-form-input>
                    <b-form-datepicker
                      v-if="fields.type === 'Date'"
                      v-model="fields.min"
                      class="date-height"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                    >
                      <template v-slot:button-content="{ }">
                        <font-awesome-icon :icon="['far', 'calendar-days']" />
                      </template>
                    </b-form-datepicker>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showMinError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('fields.min') }}
                  </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="pr-0">
                <b-form-group :label="$t('fields.field.max')" label-for="max_value">
                  <b-input-group>
                    <b-form-input v-if="fields.type !== 'Date'" id="max_value"
                      :type="defaultValueType"
                      :min="defaultValueMin"
                      :max="defaultValueMax"
                      v-model="fields.max" 
                      trim 
                      @blur="maxBlur"
                      @keydown.native="keydownHandler">
                    </b-form-input>
                    <b-form-datepicker
                      v-if="fields.type === 'Date'"
                      v-model="fields.max"
                      class="date-height"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                    >
                      <template v-slot:button-content="{ }">
                        <font-awesome-icon :icon="['far', 'calendar-days']" />
                      </template>
                    </b-form-datepicker>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col cols="12" class="pr-0" v-if="fields.type !== 'Boolean'">
                <b-form-group :label="$t('fields.field.req_regex_fmt')" label-for="req_regex_fmt">
                  <b-input-group>
                    <b-form-input id="req_regex_fmt" type="text"
                      v-model="fields.regex" 
                      trim 
                      @keydown.native="keydownHandler">
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
            </b-col>
          
            <b-col cols="12" class="pr-0">
              <b-form-group>
                <b-input-group>
                  <b-form-checkbox id="not_null"
                    v-model="fields.notNull" >
                    {{ $t('fields.field.notNull') }}
                  </b-form-checkbox>
                </b-input-group>
              </b-form-group>
            </b-col>
          
            <b-col cols="12 pr-0">
              <b-form-group :label="$t('fields.field.description')" label-for="description">
                <b-form-textarea id="description" 
                  v-model="fields.description"
                  :max-rows="6"
                  trim
                  :rows="3"/>
              </b-form-group>
            </b-col>
          </b-row>
          
          <label v-if="entitiesData.length > 1">{{ $t('fields.usage') }}</label>
          <b-row v-if="entitiesData.length > 1">
            <b-col cols="6" v-for="(entity, index) in entitiesData" :key="index">
              <b-form-group>
                <b-input-group>
                  <b-form-checkbox
                    v-model="entitiesData[index].checked" >
                    {{ entity.name }}
                  </b-form-checkbox>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </template>
      
      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="!isAccessDenied" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <template v-if="exists">
      <FieldHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="`${entity}.${id}`" entityType="FIELD" :fieldType="fields.type" />
    </template>  
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import fromExponential from 'from-exponential';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import { strRandom } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';

import { managementService, profileService } from '@/services';

export default {
  name: 'FieldModal',
  components: {
    FieldHistoryModal: () => import('@/components/modal/FieldHistoryModal')
  },
  props: {
    id:        { type: String,   default: `FIELDS_NEW_${strRandom(5)}` },
    entity:  { type: String, default: null },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    entities: { type: Array, default: () => { return  []  } },
    appendAfterOptions: { type: Array, default: () => [] }
  },
  data() {
    return {
      permissionName: 'FIELDS',
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      modalShowSelector: false,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false,
        historyShow:         false,
        showIndexWarning:    false
      },
      fields: {
        name:               null,
        displayName:        null,
        type:               null,
        min:                null,
        max:                null,
        def:                null,
        regex:              null,
        notNull:            false,
        description:        null,
        profile:            {},
        append_after:       null
      },
      entitiesData: [],
      isAccessDenied: false,

      typeOptions: [
        { value: 'Long', text: 'Long' },
        { value: 'Integer', text: 'Integer' },
        { value: 'Byte', text: 'Byte' },
        { value: 'Float', text: 'Float' },
        { value: 'Double', text: 'Double' },
        { value: 'String', text: 'String' },
        { value: 'Date', text: 'Date' },
        { value: 'Boolean', text: 'Boolean' },
        { value: 'Enum<Long>', text: 'List<Long>' },
        { value: 'Enum<Integer>', text: 'List<Integer>' },
        { value: 'Enum<Byte>', text: 'List<Byte>' },
        { value: 'Enum<Float>', text: 'List<Float>' },
        { value: 'Enum<Double>', text: 'List<Double>' },
        { value: 'Enum<String>', text: 'List<String>' }
      ],
      boolOptions: [{ text: 'TRUE', value: true},
                    { text: 'FALSE', value: false}],
      appendAfterOpts: []
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    if (!this.id.startsWith('FIELDS_NEW')) {
      this.fieldGet(this.id);
    }
    this.entitiesData = [];
    for (const entity of this.entities) {
      this.entitiesData.push({ name: entity, checked: true });
    }
  },
  mounted() {
    this.state.modalShow = this.show;
    if (this.show) {
      this.processWhenShowModal(true);
    }
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
  },
  computed: {
    defaultValueType() {
      if (this.fields.type !== null) {
        if (this.fields.type.indexOf('Long') !== -1) {
          return 'number';
        }
        else if (this.fields.type.indexOf('Integer') !== -1) {
          return 'number';
        }
        else if (this.fields.type.indexOf('Byte') !== -1) {
          return 'number';
        }
        else if (this.fields.type.indexOf('Float') !== -1) {
          return 'number';
        }
        else if (this.fields.type.indexOf('Double') !== -1) {
          return 'number';
        }
        else if (this.fields.type === 'Date') {
          return 'date';
        }
      }
      return 'text';
    },
    defaultValueMin() {
      if (this.fields.type === 'Long') {
        //return -9223372036854775808;
      }
      else if (this.fields.type === 'Integer') {
        return -2147483648 ;
      }
      else if (this.fields.type === 'Byte') {
        return -128 ;
      }
      else if (this.fields.type === 'Float') {
        //return fromExponential(-3.4028235E38) ;
      }
      else if (this.fields.type === 'Double') {
        //return fromExponential(-3.4028235E38) ;
      }
      else if (this.fields.type === 'Date') {
        return 0 ;
      }
      return null;
    },
    defaultValueMax() {
      if (this.fields.type === 'Long') {
        //return 9223372036854775807;
      }
      else if (this.fields.type === 'Integer') {
        return 2147483647;
      }
      else if (this.fields.type === 'Byte') {
        return 127;
      }
      else if (this.fields.type === 'Float') {
        //return fromExponential(3.4028235E38);
      }
      else if (this.fields.type === 'Double') {
        //return fromExponential(3.4028235E38);
      }
      else if (this.fields.type === 'Date') {
        return 32503680000000;
      }
      return null;
    },
    showMinMax() {
      return this.fields.type !== null && this.fields.type !== 'Boolean' && this.fields.type.indexOf('Enum<') === -1;
    },
    componentId() {
      return `FIELD_FORM_${this.id}`;
    },
    exists() {
      return this.id && !this.id.startsWith('FIELDS_NEW_');
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'fields.field.name');
    },
    showDisplayNameError() {
      return fieldValidateUtil.hasError(this.errors, 'fields.display_name');
    },
    showTypeError() {
      return fieldValidateUtil.hasError(this.errors, 'fields.type');
    },
    showDefError() {
      return fieldValidateUtil.hasError(this.errors, 'fields.def');
    },
    showMinError() {
      return fieldValidateUtil.hasError(this.errors, 'fields.min');
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    labelTitle() {
      return this.title? this.title: this.$t('fields.title_new');
    },
    maxNameLength() {
      var values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "name";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    locationTitle() {
      return this.locationId && this.locationId.indexOf('LOCATION_NEW') == -1? this.$t('location.title_detail'): this.$t('location.title_new');
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return true;//this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly
    },
    isDisplayNameVisible() {
      return true;
    },
    isDisplayNameReadOnly() {
      return this.isReadOnly
    },
    isTypeVisible() {
      //Type is mandatory field so checking against canAdd() can be skipped
      return true;//this.canView(this.permissionName, ['type'])
    },
    isTypeReadOnly() {
      return this.isReadOnly || this.exists;
    },
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.processWhenShowModal(newValue);
      }
    }
  },
  methods: {
    onIndex() {
      this.state.showIndexWarning = true;
    },
    onTypeChange() {
      if (this.fields.type === 'Enum<Long>') {
        this.fields.regex = '1|2|3|4|5';
      }
      else if (this.fields.type === 'Enum<Integer>') {
        this.fields.regex = '1|2|3|4|5';
      }
      else if (this.fields.type === 'Enum<Byte>') {
        this.fields.regex = '1|2|3|4|5';
      }
      else if (this.fields.type === 'Enum<Float>') {
        this.fields.regex = '1.0|2.0|3.0|4.0|5.0';
      }
      else if (this.fields.type === 'Enum<Double>') {
        this.fields.regex = '1.0|2.0|3.0|4.0|5.0';
      }
      else if (this.fields.type === 'Enum<String>') {
        this.fields.regex = 'Value 1|Value 2|Value 3|Value 4|Value 5';
      }
      else {
        this.fields.regex = null;
      }
    },
    processWhenShowModal(newValue) {
      this.$validator.resume();
      this.entitiesData = [];
      for (const entity of this.entities) {
        this.entitiesData.push({ name: entity, checked: true });
      }
      this.state.modalShow = newValue;
      this.state.autoScheduleExpanded = false;
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.state.editable =  true;
      this.fields.model = this.entity;

      this.appendAfterOpts = this.appendAfterOptions;
      if(this.id.indexOf('FIELDS_NEW_') !== -1) {
        this.resetFieldProperties();
      }
    },
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.ok();
      }
    },
    nameKeydownHandler(event) {
      var key = event.keyCode;
      if (key === 32) {
        event.preventDefault();
      }
      this.keydownHandler(event);
    },
    async fieldGet(id) {
      const self = this;
      await managementService.info({type: 'field', object: `${this.entity}.${id}`})
      .then(response => {
        self.digestResponse(response.data.propertyList[`${self.entity}.${id}`]);
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.showNoRowsOverlay(this.$t('entity_selector.error.insufficient_permission_to_show_data'))
          return null
        }
        return null
      });
      
      if (this.fields.profile) {
        const profile = await profileService.get(this.fields.profile);
        if (profile &&
            profile.data &&
            profile.data.objectList.length) {
          this.profile = profile.data.objectList[0];
          this.fields.displayName = this.profile.displayName;
          this.fields.description = this.profile.description;
          this.fields.append_after = this.profile.append_after;
        }
      }
    },
    digestResponse(data) {
      const s = this.fields;
      for (const key of Object.keys(s)) {
        if (key === 'notNull') {
          s[key] = data[key] || false;
        }
        else {
          s[key] = typeof data[key] !== 'undefined' ? data[key] : null;
        }
      }
      
      // save the original name in case the user changes the name
      s['origName'] = s['name'];
      
      if (s.type === 'Date' &&
          typeof s.def !== 'undefined') { // handle 0
        s.def = moment(s.def).format('YYYY-MM-DD');    
      }
      
      if (s.type === 'Date' &&
          typeof s.min !== 'undefined') { // handle 0
        s.min = moment(s.min).format('YYYY-MM-DD');    
      }
      
      if (s.type === 'Date' &&
          typeof s.max !== 'undefined') { // handle 0
        s.max = moment(s.max).format('YYYY-MM-DD');    
      }
      
      if (s.type === 'Double' ||
          s.type === 'Float') {
        if (s.def) {
          s.def = fromExponential(s.def);
        }   
        if (s.min) {
          s.min = fromExponential(s.min);
        }   
        if (s.max) {
          s.max = fromExponential(s.max);
        }   
      }
      
      if (typeof data.entity !== 'undefined') {
        const list = data.entity.split(',');
        for (const entity of this.entitiesData) {
          const entry = list.find(e => e === entity.name);
          if (!entry) {
            entity.checked = false;
          }
        }
      }
    },
    modalSelect() {
      this.modalShowSelector = true;
    },
    ok() {
      this.errors.clear();
      if (this.fields.notNull &&
          this.fields.def === null) {
        this.errors.add({
          field: `fields.def`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('fields.field.def')])
        });
        return;  
      }
      
      const nameValid = this.fields.name != null && /^[a-z]\w*$/.test(this.fields.name);
      if (!nameValid) {
        this.errors.add({
          field: `fields.field.name`,
          msg: this.$i18n.t('fields.error.invalid_name', [this.$i18n.t('fields.field.name')])
        });
      } else if (this.fields.name.length > 32) {
        this.errors.add({
          field: `fields.field.name`,
          msg: this.$i18n.t('fields.error.invalid_name_length', [this.$i18n.t('fields.field.name')])
        });
      }

      if (this.fields.type == null) {
        this.errors.add({
          field: `fields.type`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('fields.field.type')])
        });
        return;
      }
      
      if (parseInt(this.fields.min) > parseInt(this.fields.max)) {
        this.errors.add({
          field: `fields.min`,
          msg: this.$i18n.t('fields.error.invalid_min', [this.$i18n.t('fields.field.min')])
        });
        return;
      }

      if (this.fields.type != 'Boolean' && this.fields.regex != null && this.fields.regex.length > 0) {
        const val = this.fields.def != null? this.fields.def : '';
        let isInvalid = false;
        if (this.fields.type.startsWith('Enum<')) {
          const restrictedValues = this.fields.regex.split('|');
          if (!restrictedValues.includes(val)) {
            isInvalid = true;
          }
        } else if (val.match(this.fields.regex) == null) {
          isInvalid = true;
        } 

        if (isInvalid) {  
          this.errors.add({
            field: `fields.def`,
            msg: this.$i18n.t('fields.error.not_match_restricted_values', [this.$i18n.t('fields.field.def')])
          });
          return;
        }
      }
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
          this.fieldsSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    async fieldsSubmit() {
      const data = cloneDeep(this.fields);
      
      
      let mode = 'update';
      let successMsg = this.$t('fields.update');
  
      // format the default value if set
      if (data.type === 'Long' ||
          data.type === 'Integer' ||
          data.type === 'Byte') {
        data.def = data.def ? parseInt(data.def) : null;
        data.min = data.min ? parseInt(data.min) : null;
        data.max = data.max ? parseInt(data.max) : null;
      }
      else if (data.type === 'Float' ||
               data.type === 'Double') {
        data.def = data.def ? parseFloat(data.def) : null;
        data.min = data.min ? parseFloat(data.min) : null;
        data.max = data.max ? parseFloat(data.max) : null;
      }
      else if (data.type === 'String') {
        data.min = data.min ? parseInt(data.min) : null;
        data.max = data.max ? parseInt(data.max) : null;
      }
      else if (data.type === 'Date') {
        data.def = data.def ? moment(data.def).unix() * 1000 : null;
        data.min = data.min ? moment(data.min).unix() * 1000 : null;
        data.max = data.max ? moment(data.max).unix() * 1000 : null;
      }
            
      if(this.id.indexOf('FIELDS_NEW_') !== -1) {
        mode = 'create';
        data.profile.displayName = data.displayName;
        data.profile.description = data.description;
        data.profile.append_after = data.append_after;
        successMsg = this.$t('fields.create');
      }
      else {
        this.profile.displayName = data.displayName;
        this.profile.description = data.description;
        this.profile.append_after = data.append_after;
        profileService.update([this.profile], 'customField', 'system', '00000000-0000-0000-0000-000000000001');
        delete data['profile'];
      }

      if (this.entities.length > 1) {
        const checkedList = this.entitiesData.filter(e => e.checked);
        let list = checkedList.map(d => { return d.name }).join(',');
        
        if (checkedList.length === this.entitiesData.length) {
          // if all entities are checked set the value to null
          data.entity = null;
        }
        else {
          data.entity = list;
        }
      }
      data.model = this.entity;
      delete data.displayName;
      delete data.description;
      delete data.append_after;
      
      await this.fieldPost(mode, data, this.$t(`fields.${mode}`));
      
      let hasError = this.alertMsg !== null;
      
      if (!hasError) {
        this.$emit('update:show', false);
        this.$emit('success', { msg: successMsg, parent: this.parent });
      }
    },
    async fieldPost(method, data/**, successMsg */) {
      this.state.isSubmitting = true;
      const result = await managementService[method](data)
      .then(response => {
        const feedbackList = response.data.feedbackList;
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId;
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
        return null;
      });
      
      this.state.isSubmitting = false;
      return result;
    },
    httpAjaxError(e) {
      const response = e.response;
      let errorMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        errorMsg = this.$t('error.authorize_action');
      } else if (response && (422 === response.status || 400 === response.status)) {
        const feedback = response.data.jobClue;
        const clue = feedback.clue.trim().toLowerCase();
        if(['not_unique_key', 'missing_argument','cannot_be_blank', 
            'string_limit_exceeded', 'number_limit_exceeded', 'number_limit_over', 'string_limit_over',
            'number_limit_under', 'string_limit_under'
            ].includes(clue)) {
          errorMsg = this.$t('error.attention_required');
          const fieldKey = `fields.${feedback.args[0]}`;
          const args = [this.$t(`fields.${feedback.args[0]}`)];
          let clueNotHandled = false;
          switch (clue) {
            case 'missing_argument': //Do nothing. Doesn't need additional argument
            case 'cannot_be_blank':
              break;
            case 'string_limit_exceeded':
            case 'number_limit_exceeded':
            case 'string_limit_over':
            case 'number_limit_over':
            case 'string_limit_under':
            case 'number_limit_under':
              args.push(feedback.args[1]);
              break;
            case 'not_unique_key':
              args.push(this.$t('fields.error.not_unique_key'));
              break;
            default:
              clueNotHandled = true;
              errorMsg = this.$t('error.internal_server'); //reset the errorMsg to internal_server error.
          }
          if (!clueNotHandled) {
            this.errors.add({
              field: fieldKey,
              msg: this.$t(`fields.error.${clue}`, args)
            });
          }
        }
      }
      this.alertMsg = errorMsg;
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetFieldProperties() {
      const keys = Object.keys(this.fields);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        if (keys[i] !== 'profile') {
          this.fields[keys[i]] = null;
        }
        else {
          this.fields.profile = {};
        }
      }
    }, 
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    defaultBlur() {
      if (this.fields.def &&
          this.defaultValueMin !== null &&
          this.fields.def < this.defaultValueMin) {
        this.fields.def = this.defaultValueMin;
      }
      
      if (this.fields.def &&
          this.defaultValueMax !== null &&
          this.fields.def > this.defaultValueMax) {
        this.fields.def = this.defaultValueMax;
      }
    },
    minBlur() {
      if (this.fields.min &&
          this.defaultValueMin !== null &&
          this.fields.min < this.defaultValueMin) {
        this.fields.min = this.defaultValueMin;
      }
      
      if (this.fields.min &&
          this.defaultValueMax !== null &&
          this.fields.min > this.defaultValueMax) {
        this.fields.min = this.defaultValueMax;
      }
    },
    maxBlur() {
      if (this.fields.max &&
          this.defaultValueMin !== null &&
          this.fields.max < this.defaultValueMin) {
        this.fields.max = this.defaultValueMin;
      }
      
      if (this.fields.max &&
          this.defaultValueMax !== null &&
          this.fields.max > this.defaultValueMax) {
        this.fields.max = this.defaultValueMax;
      }
    },
    onFieldTypeOptionClick(item) {
      this.fields.type = item.value;
      this.onTypeChange();
    },
    getFieldTypeLabel() {
      return this.fields?.type != null && this.typeOptions != null && this.typeOptions.length > 0
      ? this.typeOptions.find(i => i.value == this.fields.type)?.text || ''
      : '';
    },
    onFieldAppendAfterOptionClick(item) {
      this.fields.append_after = item.value;
    },
    getFieldAppendAfterLabel() {
      return this.fields?.append_after != null && this.appendAfterOptions != null && this.appendAfterOptions.length > 0
      ? this.appendAfterOptions.find(i => i.value == this.fields.append_after)?.text || ''
      : '';
    },
  }
}
</script>
